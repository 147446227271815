import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";
import Gallery from '../Project/Gallery';

const images = [
    { 
        "src": "/images/construction/1.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/2.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/3.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/4.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/5.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/6.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/7.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/8.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/9.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/10.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/11.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/12.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/13.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/14.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/15.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/16.jpg",
        "height": 4,
        "width": 3
    },
]

export default forwardRef((props, ref) => {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToElement() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    

    return (
        <div className="section" ref={myRef}>
            <h1 className="section-title">
                <span className="title-highlight">CONSTRUCTION</span>
            </h1>
            <Row>
                <Col md={4}>
                    <iframe
                        src="https://www.youtube.com/embed/ArlZZJVCwyk" 
                        className="service-video"
                        allowFullScreen={true}
                        tabIndex="0"
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    ></iframe>
                </Col>
                <Col
                    md={8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p>
                        Uliz Construction builds high-end residential and
                        commercial buildings, integrating existing traditional
                        technologies with state of the art novelty techniques
                        and materials. We aim to create buildings that not only
                        look amazing, but that withstand the test of time. We
                        stand by the quality of our work and our products and
                        with the help of our network of architects, engineers
                        and specialty workers, deliver the desired products in a
                        timely, efficient and satisfactory manner.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Gallery 
                        photos={images}
                    />
                </Col>
            </Row>
        </div>
    );
})
