import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";

export default forwardRef((props, ref) => {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToAbout() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    return (
        <div className="section" id="about_us" ref={myRef}>
            <h1 className="section-title">
                <span className="title-highlight">ABOUT</span> ULIZCORP
            </h1>
            <Row>
                <Col md={5}>
                    <img
                        src={require("../../Assets/img/about.jpg")}
                        className={"img img-fluid about-img"}
                        alt="Ulizcorp About"
                    />
                </Col>
                <Col
                    md={7}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <h4 className="mt-4 mb-4">OUR MISSION</h4>
                    <p>
                        Uliz Corp is a novel company that seamlessly integrates
                        construction, technology, and its related market
                        analysis and media needs. It is comprised of Uliz
                        Construction, Uliz Technology, Uliz Media and Uliz
                        Analysis. We seek to create high-end constructions,
                        fitted with the latest technology for all needs, as well
                        as provide detailed media and analysis help to increase
                        the marketability of the products and guarantee a
                        positive outcome for any customer who entrusts us to
                        turn their vision into reality.
                    </p>
                </Col>
            </Row>
        </div>
    );
});
