import React, { useRef } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Banner from '../Components/Home/Banner'
import AboutSection from '../Components/Home/AboutSection'
import ServicesSection from '../Components/Home/ServicesSection'
import ProjectsSection from '../Components/Common/ProjectsSection'
import ContactUs from '../Components/Home/ContactUs'
import VRSection from '../Components/Home/VRSection'
import ConstructionSection from '../Components/Home/ConstructionSection'
import TechSection from '../Components/Home/TechSection'

export default function Home(props) {
    const aboutRef = useRef(null)
    const mediaRef = useRef(null)
    const constructionRef = useRef(null)
    const vrRef = useRef(null)
    const contactRef = useRef(null)
    const techRef = useRef(null)
    return (
        <>
            <Navbar
                isMain={true}
                aboutClick={() => aboutRef.current.scrollToAbout() }
                constructionClick={() => constructionRef.current.scrollToElement() }
                mediaClick={() => mediaRef.current.scrollToElement() }
                contactClick={() => contactRef.current.scrollToElement() }
            />
            <Banner onAboutButton={() => aboutRef.current.scrollToAbout() } />
            <AboutSection ref={aboutRef} />
            <ServicesSection />
            <ProjectsSection 
                ref={mediaRef}
                projects={window._data_.projects}
            />
            <VRSection 
                ref={vrRef}
            />
            {/* <ConstructionSection 
                ref={constructionRef}
            />
            <TechSection 
                ref={techRef}
            /> */}
            <ContactUs ref={contactRef} />
            <Footer />
        </>
    )
}
