import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";

export default forwardRef((props, ref) => {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToElement() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    return (
        <div className="section" ref={myRef}>
            <h1 className="section-title">
                VR <span className="title-highlight">TOURS</span>
            </h1>
            <Row>
                <Col md={{span: 6}}>
                    <iframe
                        src="https://roundme.com/embed/519094/1719892"
                        frameBorder="0"
                        className="vr-panel"
                        allowFullScreen={true}
                        tabIndex="0"
                    ></iframe>
                </Col>
                <Col md={{span: 6}}>
                    <iframe
                        src="https://roundme.com/embed/401060/1396733"
                        frameBorder="0"
                        className="vr-panel"
                        allowFullScreen={true}
                        tabIndex="0"
                    ></iframe>
                </Col>
            </Row>
        </div>
    );
})
