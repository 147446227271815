import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import TitleBanner from '../Components/Common/TitleBanner'
import OfferSection from '../Components/Category/OfferSection'
import InfoSection from '../Components/Category/InfoSection'
import GallerySection from '../Components/Common/GallerySection'

export default function Category(props) {

    var title = "Uliz Media"
    var image = require("../Assets/categories/media.jpg")

    if(props.type === "tech") {
        title = "Uliz Technology"
        image = "/images/tech/1.jpeg"
    }
    else if(props.type === "construction") {
        title = "Construction"
        image = "/images/construction/8.jpg"
    }

    return (
        <>
            <Navbar short={true} />
            <TitleBanner image={image} title={title} />
            <OfferSection type={props.type} />
            <InfoSection type={props.type} />
            <GallerySection type={props.type} />
            <Footer />
        </>
    )
}
