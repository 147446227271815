import React, {useState} from "react";

import { Carousel } from "react-bootstrap"

import { social } from "../../Context/PageContext";
import Slider from "react-slick";

export default function Banner({ onAboutButton }) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect} fade={true}>
            <Carousel.Item>
                <img
                    className="carousel-image d-block w-100"
                    src={"/images/banner/1.jpg"}
                    alt="UlizCorp"
                />
                <Carousel.Caption>
                <h3>Ulizcorp</h3>
                <p>Building tomorrow today</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="carousel-image d-block w-100"
                    src={"/images/banner/2.jpg"}
                    alt="UlizCorp"
                />

                <Carousel.Caption>
                <h3>Technology</h3>
                <p>We firmly believe that technology is not only the future, but the present as well.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="carousel-image d-block w-100"
                    src={"/images/banner/3.jpg"}
                    alt="UlizCorp"
                />

                <Carousel.Caption>
                <h3>Dream</h3>
                <p>
                    Aiding you visualize your dream projec
                </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}
