import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import TitleBanner from '../Components/Common/TitleBanner'
import DescriptionSection from '../Components/Project/DescriptionSection'
import Gallery from '../Components/Project/Gallery'

export default function Project({match}) {
    const project_id = match.params.slug
    console.log(project_id, window._data_.projects)
    const project = window._data_.projects.find((p) => p.id == project_id)
    // const project = window._data_.projects.find((p) => p.id === project_id)
    return (
        <>
            <Navbar short={true} />
            <TitleBanner 
                image={project.cover} 
                title={project.title}
            />
            <DescriptionSection 
                title={project.title}
                description={project.description}
            />
            <Gallery 
                photos={project.gallery}
            />
            <Footer />
        </>
    )
}
