import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";



const Media = () => 
    <div className="section">
        <h1 className="section-title">
            <span className="title-highlight">WE</span> OFFER
        </h1>
        <Row>
            <Col md={6}>
                <p>
                    Uliz Media deals primarily with aiding you visualize your
                    dream project, by providing high definition renderings and
                    animations to make your projects more marketable. We assist
                    with any artistic and media oriented details needed to make
                    your projects pop.
                </p>
            </Col>
            <Col md={6}>
                <Row>
                    <Col md={12} className="mb-4">
                        <h6>Photorealistic 3-D Renderings</h6>
                        <ProgressBar variant={"primary"} now={100} />
                    </Col>
                    <Col md={12} className="mb-4">
                        <h6>Virtual Reality Tours</h6>
                        <ProgressBar variant={"primary"} now={100} />
                    </Col>
                    <Col md={12} className="mb-4">
                        <h6>Floor Plans</h6>
                        <ProgressBar variant={"primary"} now={100} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;


const Construction = () => 
    <div className="section">
        <h1 className="section-title">
            <span className="title-highlight">WE</span> OFFER
        </h1>
        <Row>
            <Col md={7}>
                <p>
                    Uliz Construction builds high-end residential and
                    commercial buildings, integrating existing traditional
                    technologies with state of the art novelty techniques
                    and materials. We aim to create buildings that not only
                    look amazing, but that withstand the test of time. We
                    stand by the quality of our work and our products and
                    with the help of our network of architects, engineers
                    and specialty workers, deliver the desired products in a
                    timely, efficient and satisfactory manner.
                </p>
            </Col>
            <Col md={5}>
                <iframe
                    src="https://www.youtube.com/embed/ArlZZJVCwyk" 
                    className="service-video"
                    allowFullScreen={true}
                    tabIndex="0"
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                ></iframe>
            </Col>
        </Row>
    </div>;

const Tech = () => 
    <div className="section">
        <h1 className="section-title">
            <span className="title-highlight">WE</span> OFFER
        </h1>
        <Row>
            <Col md={7}>
                <p>
                    Uliz Technology specializes in home automation
                    integration, surveillance and security systems. We
                    firmly believe that technology is not only the future,
                    but the present as well. Our goal is to make all our
                    customers’ lives as comfortable and convenient as
                    possible with the integration of the most innovative new
                    technological gadgets. We want to give you peace of mind
                    by installing high-end sensors and cameras for all your
                    surveillance and security needs.
                </p>
            </Col>
            <Col md={5}>
                <iframe
                    src="https://www.youtube.com/embed/Krn9D1kGgsM" 
                    className="service-video"
                    allowFullScreen={true}
                    tabIndex="0"
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                ></iframe>
            </Col>
        </Row>
    </div>;

export default function OfferSection({type}) {
    switch(type) {
        case "construction":
            return <Construction />
        case "media":
            return <Media />
        case "tech":
            return <Tech />
        default:
            return <Media />
    }
}
