import React from 'react';
import './Assets/css/ulizcorp-bootstrap.css';
import './App.css';
import './Assets/fontawesome/css/all.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import Home from './Screens/Home';
import Category from './Screens/Category';
import Project from './Screens/Project';

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path={"/media"} render={(props) => <Category {...props} type="media" /> } />
          <Route exact path={"/tech"} render={(props) => <Category {...props} type="tech" /> } />
          <Route exact path={"/construction"} render={(props) => <Category {...props} type="construction" /> } />
          <Route path={"/project/:slug"} component={Project} />
          <Route path="*" component={Home} />
        </Switch>
      </Router>
  );
}

export default App;
