import React, {  forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Gallery from '../Project/Gallery';

const tech_images = []

const tech_images_temp = [
    { 
        "src": "/images/tech/1.jpeg",
        "height": 537,
        "width": 996
    },
    { 
        "src": "/images/tech/2.jpg",
        "height": 1200,
        "width": 1200
    },
    { 
        "src": "/images/tech/3.jpg",
        "height": 906,
        "width": 1200
    }
]

const construction_images = []

const construction_images_temp = [
    { 
        "src": "/images/construction/1.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/2.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/3.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/4.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/5.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/6.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/7.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/8.jpg",
        "height": 1,
        "width": 1
    },
    { 
        "src": "/images/construction/9.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/10.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/11.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/12.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/13.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/14.jpg",
        "height": 3,
        "width": 4
    },
    { 
        "src": "/images/construction/15.jpg",
        "height": 4,
        "width": 3
    },
    { 
        "src": "/images/construction/16.jpg",
        "height": 4,
        "width": 3
    },
]

const media_images = window._data_.projects.map((proj) => {
    return {
        src: proj.cover,
        height: proj.height,
        width: proj.width
    }
})

export default forwardRef(({type}, ref) =>  {

    var galleryImages = construction_images;
    if (type === "tech") galleryImages = tech_images;
    if (type === "media") galleryImages = media_images;

    if (galleryImages.length === 0) {
        return (<></>)
    }

    return (
        <div className="section" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <h1 className="section-title">
                <span className="title-highlight">
                {
                    type==="media" ? "ULIZ MEDIA"
                    : type==="tech" ? "ULIZ TECH"
                    : "CONSTRUCTION"
                }
                </span> PROJECTS
            </h1>
            <Row>
                <Col md={12}>
                    <Gallery 
                        photos={type==="media"?media_images:type==="tech"?tech_images:construction_images}
                    />
                </Col>
            </Row>
        </div>
    );
})
