import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";

export default forwardRef((props, ref) => {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToElement() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    return (
        <div className="section" ref={myRef}>
            <h1 className="section-title">
                GET <span className="title-highlight">IN TOUCH</span>
            </h1>
            <Row>
                <Col
                    md={5}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <h4>Address:</h4>
                    <h4 style={{ fontWeight: 200 }}>
                        1900 Market st. Philadelphia, PA 19103
                    </h4>
                    <br/>
                    <br/>
                    <br/>
                    <h4>Call Us:</h4>
                    <h4 style={{ fontWeight: 200 }}>
                        267-694-3503
                    </h4>
                    <h4 style={{ fontWeight: 200 }}>
                        contact@ulizcorp.com
                    </h4>
                </Col>
                <Col md={{span: 7}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.469078142478!2d-75.17488368532595!3d39.95326399162087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c636d6e60711%3A0x77dce7318b0d9647!2s1900%20Market%20St%2C%20Philadelphia%2C%20PA%2019103%2C%20USA!5e0!3m2!1sen!2s!4v1594069167767!5m2!1sen!2s"
                        frameBorder="0"
                        className="contact-map"
                        allowFullScreen=""
                        tabIndex="0"
                    ></iframe>
                </Col>
            </Row>
        </div>
    );
})
