import React, {  forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Slider from "react-slick";

const ProjectCard = ({ id, image, title, category }) => {
    title = title.toUpperCase().split(" ");

    return (
        <Link to={"project/"+id}>
            <div className="project-container">
                <img src={image} className="project-image" />
                <div className="image-overlay"></div>
                <div className="project-title-container">
                    {title.map((t, index) => (
                        <h5 key={t + "-" + index}>{t}</h5>
                    ))}
                </div>
                <div className="project-category-container">
                    <h5>{category.toUpperCase()}</h5>
                </div>
            </div>
        </Link>
    );
};

export default forwardRef(({projects}, ref) =>  {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToElement() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    var settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="section" style={{ paddingLeft: 0, paddingRight: 0 }} ref={myRef}>
            <h1 className="section-title">
                <span className="title-highlight">ULIZ MEDIA</span> PROJECTS
            </h1>
            <Row>
                <Col md={12}>
                {projects &&
                    <Slider {...settings}>
                        {projects.map((p, index) => {
                            return(
                                <ProjectCard
                                    key={"all-projects-"+index}
                                    id={p.id}
                                    image={p.cover}
                                    title={p.title}
                                    category={p.category}
                                />
                            )
                        })}
                    </Slider>
                }
                </Col>
            </Row>
        </div>
    );
})
