import React from "react";
import { Row, Col } from "react-bootstrap";

const Media = () => <div className="section">
            <Row>
                <Col md={4} className="text-center">
                    <h4 className="mb-4">Visualize your project</h4>
                    <p>
                        Make your projects tangible, help them jump off the
                        page.
                    </p>
                </Col>
                <Col md={4} className="text-center">
                    <h4 className="mb-4">Accelerate your listing</h4>
                    <p>
                        Our customers have been able to list their projects
                        months in advance with our help, increasing their
                        exposure early
                    </p>
                </Col>
                <Col md={4} className="text-center">
                    <h4 className="mb-4">Create your vision</h4>
                    <p>
                        Financing and lending partners have reported that their
                        are more likely to finance a project if they can see
                        what the vision is.
                    </p>
                </Col>
            </Row>
        </div>;


export default function InfoSection({type}) {
    switch(type) {
        case "construction":
            return <></>
        case "media":
            return <Media />
        case "tech":
            return <></>
        default:
            return <></>
    }
}
