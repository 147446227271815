import React from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";

export default function DescriptionSection({ title, description }) {
    return (
        <div className="section">
            <h1 className="section-title">
                <span className="title-highlight">{title}</span>
            </h1>
            <Row>
                <Col md={12}>
                    <p>{description}</p>
                </Col>
            </Row>
        </div>
    );
}
