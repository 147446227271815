import React, {useState, useEffect} from "react";
import {
    Navbar,
    Nav
} from "react-bootstrap";

import {
    Link
} from "react-router-dom"

export default function UlizNavbar(props) {
    const [isDown, setIsDown] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', e => {
            var scrolled = document.scrollingElement.scrollTop;

            var limit = window.screen.height * 0.6;
            if (props.short) {
                limit = window.screen.height * 0.35;
            } 

            if (scrolled >= limit) {
                if(!isDown) {
                    setIsDown(true)
                }
            }
            else {
                setIsDown(false)
            }
        })

        return () => document.removeEventListener('scroll', e=>{})
    }, [])

    return (
        <Navbar collapseOnSelect fixed="top" expand="lg" variant="dark" style={isDown ? {background: "#FFFFFF"} : {}}>
            <Link to="/" className="brand-space">
                <img
                    src={isDown ? require("../Assets/logo/uliz-corp-black.png") : require("../Assets/logo/uliz-corp.png")}
                    style={{ height: 30, width: "auto" }}
                    alt="Uliz Corp Brand"
                />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="mr-auto"></div>
                <Nav>
                    {
                        props.isMain ?
                        <Nav.Link className={isDown ? "dark nav-link" : "nav-link"} href="" onClick={(e) => {e.preventDefault(); props.aboutClick()}}>ABOUT</Nav.Link>
                        :
                        <Link to="/#about_us" className={isDown ? "dark nav-link" : "nav-link"}>ABOUT</Link>
                    }
                    <Link to="/construction" className={isDown ? "dark nav-link" : "nav-link"}>CONSTRUCTION</Link>
                    <Link to="/tech" className={isDown ? "dark nav-link" : "nav-link"}>TECHNOLOGY</Link>
                    <Link to="/media" className={isDown ? "dark nav-link" : "nav-link"}>MEDIA</Link>
                    {
                        props.isMain ?
                        <Nav.Link className={isDown ? "dark nav-link" : "nav-link"} href="" onClick={(e) => {e.preventDefault(); props.contactClick()}}>CONTACT US</Nav.Link>
                        :
                        <Link to="/#contact_us" className={isDown ? "dark nav-link" : "nav-link"}>CONTACT US</Link>
                    }
                    
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

UlizNavbar.defaultProps = {
    isMain: false
}

