import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col } from "react-bootstrap";
import Gallery from '../Project/Gallery';

const images = [
    { 
        "src": "/images/tech/1.jpeg",
        "height": 537,
        "width": 996
    },
    { 
        "src": "/images/tech/2.jpg",
        "height": 1200,
        "width": 1200
    },
    { 
        "src": "/images/tech/3.jpg",
        "height": 906,
        "width": 1200
    }
]

export default forwardRef((props, ref) => {
    const myRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollToElement() {
            window.scrollTo(0, myRef.current.offsetTop);
        },
    }));

    

    return (
        <div className="section" ref={myRef}>
            <h1 className="section-title">
                <span className="title-highlight">Technology</span>
            </h1>
            <Row>
                <Col md={4}>
                    <iframe
                        src="https://www.youtube.com/embed/Krn9D1kGgsM" 
                        className="service-video"
                        allowFullScreen={true}
                        tabIndex="0"
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    ></iframe>
                </Col>
                <Col
                    md={8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p>
                        Uliz Technology specializes in home automation
                        integration, surveillance and security systems. We
                        firmly believe that technology is not only the future,
                        but the present as well. Our goal is to make all our
                        customers’ lives as comfortable and convenient as
                        possible with the integration of the most innovative new
                        technological gadgets. We want to give you peace of mind
                        by installing high-end sensors and cameras for all your
                        surveillance and security needs.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Gallery 
                        photos={images}
                    />
                </Col>
            </Row>
        </div>
    );
})
