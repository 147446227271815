import React from "react";
import { Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";

export default function UlisFooter() {
    return (
        <div className="footer">
            <hr />
            <Row>
                <Col md="12">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Link to="/" className="brand-space">
                            <img
                                src={require("../Assets/logo/uliz-corp-black.png")}
                                style={{ height: 30, width: "auto" }}
                                alt="Uliz Corp Brand"
                            />
                        </Link>
                        <div className="text-center" style={{display: 'flex', justifyContent: 'center'}}>
                            UlizCorp © all rights reserved 2020
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
