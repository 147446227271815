import React from "react";
import { social } from "../../Context/PageContext";

export default function Banner({ image, title }) {
    return (
        <>
            <img
                src={image}
                className="banner-image-category"
                alt="Building your tomorrow today"
            />
            <div className="banner-overlay-category"></div>
            <div style={{ display: "flex", height: "60vh" }}>
                <span className="banner-category">
                    <h1 className="banner-title">{title}</h1>
                </span>
            </div>
        </>
    );
}

Banner.defaultProps = {
    title: "Ulizcorp",
};
