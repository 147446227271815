import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

export default function ServicesSection() {
    return (
        <div className="section">
            <h1 className="section-title">
                OUR <span className="title-highlight">SERVICES</span>
            </h1>
            <Row>
                <Col md={4}>
                    <div className="service-icon-container mb-5">
                        <img src={require("../../Assets/icons/sketch.svg")} className="service-icon" />
                    </div>
                    <Link className="nav-link dark" to="/construction">
                        <h4 className="text-center mb-3">CONSTRUCTION</h4>
                    </Link>
                    <p>
                        Uliz Construction builds high-end residential and
                        commercial buildings, integrating existing traditional
                        technologies with state of the art novelty techniques
                        and materials. We aim to create buildings that not only
                        look amazing, but that withstand the test of time. We
                        stand by the quality of our work and our products and
                        with the help of our network of architects, engineers
                        and specialty workers, deliver the desired products in a
                        timely, efficient and satisfactory manner.
                    </p>
                </Col>
                <Col md={4}>
                    <div className="service-icon-container mb-5">
                        <img src={require("../../Assets/icons/code.svg")} className="service-icon" />
                    </div>
                    <Link className="nav-link dark" to="/tech">
                        <h4 className="text-center mb-3">TECHNOLOGY</h4>
                    </Link>
                    <p>
                        Uliz Technology specializes in home automation
                        integration, surveillance and security systems. We
                        firmly believe that technology is not only the future,
                        but the present as well. Our goal is to make all our
                        customers’ lives as comfortable and convenient as
                        possible with the integration of the most innovative new
                        technological gadgets. We want to give you peace of mind
                        by installing high-end sensors and cameras for all your
                        surveillance and security needs.
                    </p>
                </Col>
                <Col md={4}>
                    <div className="service-icon-container mb-5">
                        <img src={require("../../Assets/icons/video.svg")} className="service-icon" />
                    </div>
                    <Link className="nav-link dark" to="/media">
                        <h4 className="text-center mb-3">ULIZ MEDIA</h4>
                    </Link>
                    <p>
                        Uliz Media deals primarily with aiding you visualize
                        your dream project, by providing high definition
                        renderings and animations to make your projects more
                        marketable. We assist with any artistic and media
                        oriented details needed to make your projects pop.
                    </p>
                </Col>
            </Row>
        </div>
    );
}
